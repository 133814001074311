import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// @mui/material components
import MenuItem from "@mui/material/MenuItem";

// @mui/icons-material
import List from "@mui/icons-material/List";
import Description from "@mui/icons-material/Description";

// core components
import GridItem from "components/Grid/GridItem";
import GridContainer from "components/Grid/GridContainer";
import TablePopUpContainer from "components/TablePopUpContainer/TablePopUpContainer";
import Button from "components/Button/Button";
import MenuBottom from "components/MenuBottom/MenuBottom";
import TextInput from "components/CustomInput/TextInput";
import SelectInput from "components/CustomSelect/SelectInput";
import Render from "Utils/RenderUtils";
import OperationUtils from "Utils/OperationUtils";
import DatePicker from "components/DatePicker/DatePicker.jsx";
import { withUserContext } from "context/UserContext";
import DateUtils from "Utils/DateUtils";

class OperationComplementaireConfig extends Component {
  constructor(props) {
    super(props);
    this.USER = props.user;
    this.CAN_VIEW_LOG_ACTIVITY = this.USER.can("view.log.activity");
    this.CAN_CONFIG_OPERATIONS = this.USER.canOne([
      "config.operationcomplementaire",
    ]);
    this.FRONT_URL = "/configuration/operations-complementaires";

    this.state = {
      // SETTINGS FORM
      // DATA FORM
      id: "",
      label: "",
      date: new Date(),
      montant: "",
      debit: false,
      compte: "",
      // END DATA FORM
      setErrs: null,
      opErrs: null,
    };
  }

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  handleChangeEvent = (event) => {
    this.handleChange(event.target.name, event.target.value);
  };

  handleChangeByName = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const { opErrs } = this.state;

    return (
      <GridContainer>
        {this.CAN_CONFIG_OPERATIONS && (
          <GridItem xs={12} lg={6}>
            <TablePopUpContainer
              icon={<List />}
              title="Configuration des opérations complémentaires"
              backendUrl="/config-operations-complementaire"
              colDef={[
                { field: "id", editable: false, hide: true },
                {
                  headerName: "Libellé",
                  field: "label",
                },
                {
                  headerName: "Date",
                  field: "date",
                  cellDataType: "date",
                  valueFormatter: (params) => Render.date(params.value),
                },
                {
                  headerName: "Montant",
                  field: "montant",
                  cellDataType: "number",
                  type: "numericColumn",
                  valueFormatter: (params) => Render.number(params.value),
                },
                {
                  headerName: "Débit/Crébit",
                  field: "debit",
                  valueGetter: (params) =>
                    OperationUtils.renderDebitCredit(params.data.debit),
                },
                {
                  headerName: "Compte",
                  field: "compte",
                },
              ]}
              formInitData={() => {
                this.setState({
                  opErrs: null,
                  id: "",
                  label: "",
                  date: new Date(),
                  montant: "",
                  debit: false,
                  compte: "",
                });
              }}
              formSetData={(data) => {
                this.setState({
                  opErrs: null,
                  id: data.id ?? "",
                  date: data.date ? new Date(data.date) : new Date(),
                  label: data.label ?? "",
                  montant: data.montant ?? "",
                  debit: data.debit ?? false,
                  compte: data.compte ?? "",
                });
              }}
              formGetData={() => ({
                id: this.state.id,
                label: this.state.label,
                date: DateUtils.toDBString(this.state.date),
                montant: this.state.montant,
                debit: this.state.debit,
                compte: this.state.compte,
              })}
              formSetErrors={(err) => {
                this.setState({
                  opErrs: err,
                });
              }}
              getDeleteContent={(data) => data.label}
              formTitle={
                this.state.id
                  ? "Modifier l'opération complémentaire"
                  : "Ajouter une opération complémentaire"
              }
              formConfirmLabel={this.state.id ? "Modifier" : "Ajouter"}
              formContent={
                <GridContainer alignItems="center">
                  <GridItem xs={12} lg={6}>
                    <TextInput
                      label="Libellé"
                      name="label"
                      value={this.state.label}
                      onChange={this.handleChangeEvent}
                      error={Boolean(opErrs?.label)}
                    />
                  </GridItem>
                  <GridItem xs={12} lg={6}>
                    <DatePicker
                      label="Date"
                      name="date"
                      value={this.state.date}
                      onChange={this.handleChangeByName}
                      error={Boolean(opErrs?.date)}
                    />
                  </GridItem>
                  <GridItem xs={12} lg={6}>
                    <TextInput
                      label="Montant"
                      name="montant"
                      onChange={this.handleChangeEvent}
                      value={this.state.montant}
                      error={Boolean(opErrs?.montant)}
                    />
                  </GridItem>
                  <GridItem xs={12} lg={6}>
                    <SelectInput
                      name="debit"
                      label="Débit ou Crédit ?"
                      value={this.state.debit}
                      onChange={this.handleChangeEvent}
                      error={Boolean(opErrs?.date)}
                      shrink
                    >
                      <MenuItem value={1}>Débit</MenuItem>
                      <MenuItem value={0}>Crédit</MenuItem>
                    </SelectInput>
                  </GridItem>
                  <GridItem xs={12} lg={6}>
                    <TextInput
                      label="Compte"
                      name="compte"
                      value={this.state.compte}
                      onChange={this.handleChangeEvent}
                      error={Boolean(opErrs?.compte)}
                    />
                  </GridItem>
                </GridContainer>
              }
            />
          </GridItem>
        )}
        {this.CAN_VIEW_LOG_ACTIVITY && (
          <MenuBottom>
            <Link
              to={this.FRONT_URL + "/logs?type=ConfigOperationComplementaire"}
            >
              <Button size="small" square round>
                <Description />
              </Button>
            </Link>
          </MenuBottom>
        )}
      </GridContainer>
    );
  }
}

OperationComplementaireConfig.propTypes = {
  match: PropTypes.any,
  history: PropTypes.any,
  user: PropTypes.object,
};

export default withUserContext(OperationComplementaireConfig);
